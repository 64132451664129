import { css } from "emotion";
import propTypes from "prop-types";
import React from "react";

import {FaTwitter} from "react-icons/fa";
import {FaFacebook} from "react-icons/fa";
import {FaLinkedin} from "react-icons/fa";


import { ShareButtonRectangle, ShareBlockStandard, ShareButtonIconOnly } from "react-custom-share";


const CustomReactShare = props => {
  const { url, title, excerpt } = props;

  
  const customStyles = css`
    border-radius: 50% 0 50% 0;
    margin: 0 10px;
    flex-basis: 60px;
    height: 60px;
    flex-grow: 0;
  `;

  const shareBlockProps = {
    url: url,
    button: ShareButtonIconOnly,
    buttons: [
      { network: "Twitter", icon: FaTwitter },
      { network: "Facebook", icon: FaFacebook },
      { network: 'Linkedin', icon: FaLinkedin }
    ],
    text: title,
    longtext: excerpt
    ,buttonCustomClassName: customStyles
  };

  return <ShareBlockStandard {...shareBlockProps} />;

  /*return <h1>{title}</h1>*/

};

CustomReactShare.propTypes = {
  url: propTypes.string,
  title: propTypes.string,
  excerpt: propTypes.string
};

CustomReactShare.defaultProps = {
  url: "http://thinking.doneger.com",
  title: "thinking.doneger.com",
  excerpt: "Default value of excerpt"
};

export default CustomReactShare;