import React, { Component } from 'react'
import axios from "axios";
import bulb from '../images/bulb.svg'

class ContactForm extends Component {
    constructor(props) {
    super(props);
    this.state = {name: '', email:'',loading: false, submitted:false};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.postData = this.postData.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }
    
    handleSubmit(event) {
      event.preventDefault();
      this.setState({loading: true}, this.postData);
      /*
        alert('A name was submitted: ' + this.state.name + ' / ' + this.state.email);
        event.preventDefault();
        axios.post('http://dev.doneger.com/post.php', {
          dwFirstName: 'Fred',
          dwLastName: 'Flintstone'
        })
        .then(function (response) {
          console.log(response);
        })
        */

        /*
        let data = {dwFirstName: 'eeeeee', dwLastName: 'Sit'};
        await fetch("http://dev.doneger.com/post.php", {
          method: 'POST',
          body: JSON.stringify(data),
          headers: new Headers({
            'Content-Type': 'application/json'
          })
        }).then(response => {

            response.json().then(json => {
              console.log(json);
            });


        });
*/
        
    }

    postData(){
      let data = {dwFirstName: this.state.name, dwEmail: this.state.email};
        let trt = JSON.stringify(data)
        axios.post('http://dev.doneger.com/post.php', trt, {
          headers: {
              'Content-Type': 'application/json',
          }
        })
        .then((response) => {
          setTimeout(() => {
            this.setState({name: '', email:'', loading: false, submitted: true});
          }, 2000);
          //this.setState({name: ""});
        })
        .catch((e) => 
        {
          console.error(e);
        });
    }

  
    render() {
      if(this.state.submitted){
        return(
          <div class="subBox">
            <div>SUCCESS</div>
          </div>  
        )
      }       
      if(this.state.loading){
        return(
          <div class="subBox">
            <div className="loader"></div>
          </div>
        )
      } 

      return (
        <div class="subBox">
          <div style={{flex: "0 0 10%",flexDirection: "column", justifyContent: "center", alignItems:"center"}}>
            <img src={bulb} style={{width:"100%"}}/>
          </div>
          <div style={{flex: "0 0 55%",flexDirection: "column", justifyContent: "center", alignItems:"center", paddingLeft: "5%"}}>
     
              <h3>Subscribe to Doneger Thinking</h3>
              <p style={{margin:0}}>Want more thoughts in your inbox?</p>
            
          </div>  
          <form onSubmit={this.handleSubmit} style={{display: "flex", flexDirection: "column"}}>
              <input className="txtField" type="text" id="email" value={this.state.email} onChange={this.handleChange} placeholder="Email" style={{marginBottom:"10px"}}/>
            <input className="pure-button" type="submit" value="Submit" />
          </form>
        </div>  
      );
    }
  }

  export default ContactForm


  /*
      <input className="txtField" type="text" id="name" value={this.state.name} onChange={this.handleChange} placeholder="Name"/>

      */