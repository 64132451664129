
import React from 'react'
import { MDXProvider } from '@mdx-js/tag'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import Layout from './layout'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import ContactForm from './ContactForm'

import CustomReactShare from "./CustomReactShare";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const pageContext = this.props.pageContext
    return (
      <Layout>
        <Helmet>
          <title>{post.frontmatter.title}</title>
          <link href="//cloud.typography.com/6710554/687028/css/fonts.css" rel="stylesheet" type="text/css"/>
          <link href="//cloud.webtype.com/css/c2f30112-2f02-42ee-8852-ff3a742a7c6f.css" rel="stylesheet" type="text/css" />
          <link rel="stylesheet" href="https://use.typekit.net/tkq8lmk.css"/>
        </Helmet>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1000, 
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
        <article>

          <header className="headerInfo">
            <h1>{post.frontmatter.title}</h1>
            <p>{post.frontmatter.author}{post.frontmatter.authorTitle ? `, ${post.frontmatter.authorTitle}`:``}</p>
            <p style={{margin:0}}>{post.frontmatter.date}</p>
          </header>
          
          <div style={{display: "flex", flexWrap: "nowrap"}}>
              <div style={{width: "160px", background:"#fff", paddingRight: "30px"}}>
                <CustomReactShare title={post.frontmatter.title} excerpt={post.frontmatter.teaserText} url={`http://dev.doneger.com/pages/${post.frontmatter.path}`} />
              </div>
              <div style={{}}>
                  <MDXProvider>
                    <MDXRenderer>{post.code.body}</MDXRenderer>
                  </MDXProvider>

                  <div className="articleNav">
                      {pageContext.previous ? <Link className="articleNav articleNav__link articleNav__link--next" to={pageContext.previous.fields.slug}>Previous Post</Link> : ''}
                      {pageContext.next ? <Link className="articleNav articleNav__link articleNav__link--prev" to={pageContext.next.fields.slug}>Next Post</Link> : ''}
                  </div>     
             
                     
            
            </div>  
          </div>

         

        </article>
        </div>
      </Layout>
    )
  }
}



export const pageQuery = graphql`
query PostQuery($slug: String!) {
  mdx(fields: { slug: { eq: $slug } }) {          
    fields {
        slug
    }
    id
    excerpt
    code {
      body
      scope
    }
    frontmatter{
      title
      date(formatString: "MMMM DD, YYYY")
      teaserText
      author
      authorTitle
      path
      teaserImage {
          childImageSharp{
              sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
              }
          }
      }
    }
  }         
}
`
export default BlogPostTemplate


/*
            <p>path: {post.frontmatter.path}</p>

            */